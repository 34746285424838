import __Base from '@components/Base';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
class Or extends __Base {
  render = () => {
    const {
      customAttributes,
      mood,
      text
    } = this.props;
    return <div data-attr='root' {..._.get(customAttributes, 'root', {})} className={clsx('or', mood && `/${mood}`, _.get(customAttributes, 'root.className'))}>
                <div className='-wing' />
                {!!text && <div className='-body' data-test-id={`divider-${text}`}>
                        {text}
                    </div>}
                <div className='-wing' />
            </div>;
  };
}
Or.defaultProps = {
  customAttributes: {},
  mood: null,
  text: '***'
};
Or.propTypes = {
  // optional
  customAttributes: PropTypes.object,
  mood: PropTypes.oneOf(['danger', 'info', 'warning']),
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
export default Or;