import { original } from 'immer';
import * as _ from 'lodash';
import eagerCloneDeep from './eagerCloneDeep';
import isBasicObject from './isBasicObject';

export default function applyDeep(data, tester = null, callback = null, greedy = false) {
    if (!data) return data;

    if (original(data)) data = original(data); // handle immer Proxy instances

    const res = eagerCloneDeep(data);

    if (_.isObject(res)) {
        _.forOwn(res, (value, k) => {
            if (tester === null || (_.isFunction(tester) && tester(value)) || k === tester) {
                const newStuff = _.isFunction(callback) ? callback(value, k) : callback;
                let newKey = null;
                let newValue = null;

                if (
                    isBasicObject(newStuff) &&
                    Object.keys(newStuff).length === 2 &&
                    typeof newStuff.key !== 'undefined' &&
                    typeof newStuff.value !== 'undefined'
                ) {
                    delete res[k];
                    newKey = newStuff.key;
                    newValue = newStuff.value;
                } else {
                    newKey = k;
                    newValue = newStuff;
                }

                res[newKey] = newValue;

                if (!(_.isObject(res[k]) && (_.isFunction(greedy) ? greedy(value, k) : greedy))) return;

                res[newKey] = applyDeep(res[newKey], tester, callback, greedy);
            } else {
                res[k] = applyDeep(res[k], tester, callback, greedy);
            }
        });
    }

    return res;
}
