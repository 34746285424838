import __Base from '@components/Base';
import clsx from 'clsx';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { CustomInput, Input, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import dynamic from 'next/dynamic';
import { isEnvTest } from '@utils/getEnv';
const Editor = dynamic(() => import('@components/bridge/bridge/DanimEditor'), {
  ssr: false
});
class DanimInput extends __Base {
  static getDerivedStateFromProps = (nextProps, prevState) => {
    return {
      // mirroring, see https://stackoverflow.com/a/50080417/6503789
      ...((typeof _.get(prevState, '_value') === 'undefined' || nextProps.value !== _.get(prevState, '_value')) && {
        _value: nextProps.value
      }),
      // derivation
      ...(nextProps.value !== prevState._value && {
        value: _.includes([undefined, false, null], nextProps.value) ? '' : nextProps.value
      })
    };
  };
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      isComposing: false,
      fileHelper: {
        file: null,
        placeholder: ''
      }
    };
    this.updateContextDebounce = isEnvTest ? this.updateContext : _.debounce(this.updateContext, 100);
  }
  componentDidMount = () => {
    const {
      mountFocus
    } = this.props;
    if (mountFocus) this.innerInput.focus();
    window.addEventListener('compositionstart', this.handleCompositionStart);
    window.addEventListener('compositionend', this.handleCompositionEnd);
  };
  componentWillUnmount = () => {
    window.removeEventListener('compositionstart', this.handleCompositionStart);
    window.removeEventListener('compositionend', this.handleCompositionEnd);
  };
  focus = () => this.innerInput.focus();
  handleCompositionStart = () => this.setState({
    isComposing: true
  });
  handleCompositionEnd = () => this.setState({
    isComposing: false
  });
  handleBlur = ev => {
    const {
      type
    } = this.props;
    if (type !== 'javascript') {
      ev.persist();
      this.updateContextDebounce(ev);
    } else {
      this.updateContextDebounce(this.state.value);
    }
  };
  handleChange = ev => {
    const {
      distinctNewLines,
      type,
      value
    } = this.props;
    const {
      isComposing
    } = this.state;
    if (type === 'textarea' && distinctNewLines && !isComposing) {
      let selection = getInputSelection(ev.target);
      if (typeof this.lastValue === 'undefined') this.lastValue = value;
      const nbOfEntersInLastValue = this.lastValue.split(/\n/).length - 1;
      const nbOfEntersInNewValue = ev.target.value.split(/\n/).length - 1;
      const pattern = new RegExp(/(^|[^\n])\n(\n{2})*([^\n]|$)/g);
      const appendedText = _.get(ev, 'nativeEvent.data') || '';
      if (ev.target.value.length >= this.lastValue.length) {
        // >= and not >, because tapping ^ followed by e would result in ê
        if (!_.includes(['^', '¨', '"', 'ˇ', '`', '˜', '~'], appendedText)) {
          const marker = ':+*_->(8)<-_*+:'; // user will never type that...

          // insert marker at caret position
          ev.target.value = [ev.target.value.slice(0, selection.start), marker, ev.target.value.slice(selection.start)].join('');
          let replaceCount = (ev.target.value.match(pattern) || []).length;
          for (let i = 0; i < replaceCount; i++) ev.target.value = ev.target.value.replace(pattern, '$1\n\n$2$3');
          const position = ev.target.value.indexOf(marker);
          selection.start = position;
          selection.end = position;
          ev.target.value = ev.target.value.replace(marker, '');
        }
      } else {
        const removedEnter = nbOfEntersInNewValue < nbOfEntersInLastValue;
        if (removedEnter) {
          const char = ev.target.value[selection.start - 1];
          if (char === '\n') {
            selection.start--;
            selection.end--;
          }
        }
        ev.target.value = ev.target.value.replace(pattern, '$1$2$3');
      }
      setInputSelection(ev.target, selection.start, selection.end);
      this.lastValue = ev.target.value;
    }
    if (type !== 'javascript') {
      ev.persist();
      this.updateState({
        value: ev.target.value
      }, () => {
        if (ev.which === 13) this.updateContextDebounce(ev); // trigger now when user presses enter
        else this.updateContextDebounce(ev);
      });
    } else this.updateContextDebounce(ev);
  };
  handleFileHelperChange = ev => {
    const {
      onFileHelperChange
    } = this.props;
    const file = ev.target.files[0];
    if (!file) return;
    this.updateState(draft => {
      draft.fileHelper.file = file;
      draft.fileHelper.placeholder = file.name;
    });
    const reader = new FileReader();
    reader.onload = () => onFileHelperChange(reader.result);
    reader.readAsText(file);
  };
  handleKeyDown = ev => {
    const {
      onKeyDown,
      type
    } = this.props;
    if (ev.which === 13 && type !== 'javascript') {
      ev.persist();
      this.updateContextDebounce(ev);
    }
    onKeyDown(ev);
  };
  inputType = type => {
    switch (type) {
      case 'javascript':
        return 'editor';
      default:
        return 'input';
    }
  };
  updateContext = stuff => this.props.onChange(stuff);
  render = () => {
    // rcl('input')

    const {
      append,
      customAttributes,
      error,
      helper,
      label,
      name,
      placeholder,
      prepend,
      readOnly,
      showGutter,
      showPrintMargin,
      type,
      warning,
      withFileHelper,
      autoComplete
    } = this.props;
    let input = null;
    switch (this.inputType(type)) {
      case 'input':
        input = <Input data-test-id={name} ref={obj => this.input = obj} innerRef={obj => this.innerInput = obj} data-attr='input' {..._.get(customAttributes, 'input', {})} className={clsx('-input', _.get(customAttributes, 'input.className'), withFileHelper && '/fileHelped', warning && '/warning', error && '/error', prepend && '/prepended', append && '/appended')} type={type} readOnly={readOnly} name={name} placeholder={placeholder} value={this.state.value ?? ''} onChange={this.handleChange} onBlur={this.handleBlur} onKeyDown={this.handleKeyDown} autoComplete={autoComplete} />;
        break;
      case 'editor':
        input = <Editor data-attr='input' {..._.get(customAttributes, 'input', {})} className={clsx('-input', '/editor', _.get(customAttributes, 'input.className'), withFileHelper && '/fileHelped')} editorProps={{
          $blockScrolling: true
        }} name={name} onChange={this.handleChange} onBlur={this.handleBlur} onKeyDown={this.handleKeyDown} fontSize={14} readOnly={readOnly} showGutter={showGutter} showPrintMargin={showPrintMargin} highlightActiveLine value={this.state.value || ''} setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          useWorker: false,
          tabSize: 2
        }} />;
        break;
    }
    const feedback = error || warning || helper || null;
    return <div ref={obj => this.root = obj} data-attr='root' {..._.get(customAttributes, 'root', {})} className={clsx('input', _.get(customAttributes, 'root.className'))}>
                {(label || '') !== '' && <Label for={name}>{label}</Label>}
                {withFileHelper === true && <CustomInput id='fileHelper' className='-fileHelper' type='file' name='fileHelper' label={this.state.fileHelper.placeholder || tr('front.file.placeholder.one')} onChange={this.handleFileHelperChange} />}
                <div className='-shell'>
                    {prepend !== null && <InputGroupAddon addonType='prepend'>
                            <InputGroupText>{prepend}</InputGroupText>
                        </InputGroupAddon>}
                    {input}
                    {append !== null && <InputGroupAddon addonType='append'>
                            <InputGroupText>{append}</InputGroupText>
                        </InputGroupAddon>}
                </div>
                {(feedback && feedback !== true) === true && <div className={clsx('-feedback', warning && '/warning', error && '/error')} style={{
        display: 'initial'
      }} data-test-id={`feedback-${name}`}>
                        {feedback}
                    </div>}
            </div>;
  };
}
DanimInput.defaultProps = {
  append: null,
  caption: '',
  customAttributes: {},
  distinctNewLines: false,
  error: null,
  helper: null,
  label: '',
  name: '',
  onChange: stuff => null,
  // stuff may be a value (type: javascript) or an event
  onFileHelperChange: value => null,
  onKeyDown: ev => null,
  onlyInteger: false,
  placeholder: '',
  prepend: null,
  readOnly: false,
  showGutter: true,
  showPrintMargin: false,
  type: 'text',
  value: '',
  warning: null,
  withFileHelper: false,
  autoComplete: ''
};
DanimInput.propTypes = {
  // optional
  append: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  caption: PropTypes.oneOfType([PropTypes.node, PropTypes.bool, PropTypes.string]),
  customAttributes: PropTypes.object,
  distinctNewLines: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.node]),
  helper: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  // parameter (input) may be event or value
  onFileHelperChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onlyInteger: PropTypes.bool,
  placeholder: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  readOnly: PropTypes.bool,
  showGutter: PropTypes.bool,
  showPrintMargin: PropTypes.bool,
  type: PropTypes.oneOf(['date', 'datetime-local', 'hidden', 'javascript', 'number', 'password', 'text', 'textarea', 'time']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.node]),
  withFileHelper: PropTypes.bool,
  autoComplete: PropTypes.string
};
export default DanimInput;